import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/posts-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Thème du défi : Les histoires imbriquées (des histoires dans des histoires dans des histoires dans des etc...)`}</em></p>
    <p>{`Nous sommes le 17 Septembre 2022, je m’appelle Franck Cornec et je viens finalement d’achever l’oeuvre d’une vie, la machine à remonter le temps. Vous vous demandez sûrement comment je l’ai accompli aussi vite alors que si peu de recherche n’avait abouti. Même si je peux vous donner une explication, cela vous laissera encore plus dubitatif. J’ai complété cet exploit grace au manuel de la Réalité Recursive qui m’a été remis par un inconnu, le 21 Janvier 2021, avec un sac d’objet. Lui demandant comment il s’est procuré ce manuel et comment il savait que je faisais des recherches sur le sujet depuis plusieurs années, je fus décontenancé par sa réponse, il avait un récit rocambolesque à partager. Cette histoire était difficile à avaler mais il m’était tout aussi difficile de me croire en possession de ce manuel. Qui suis-je pour douter de son récit ?`}</p>
    <p>{`Cet homme me confia qu’il avait eu instruction de me remettre ces objets d’une autre personne au cours d’une expérience des plus troublantes. Il se reposait chez lui au cours d’un des dimanche les plus calmes qu’il avait eu le plaisir de vivre. Tranquillement posé dans son canapé à regarder des programmes tous plus insipides les uns que les autres, une personne s’infiltra chez lui. Se saisissant d’une batte de baseball, prêt à affronter le cambrioleur, il fut surpris par la sensation de l’extrémité d’une arme dans son dos. Se retournant d’un bond, son ravisseur lui faisait face d’un air aussi apeuré que lui. Il le menaçait d’un taser d’une main et brandissait les clés de la maison de l’autre main. Ce cambrioleur prétendait devoir lui remettre ce manuel et ces deux objets afin de les transmettre à un certain Franck Cornec - moi même. Quand l’homme l’interrogea sur l’origine de tous ces objets et surtout sur la façon dont il s’est procuré les clés de chez lui, le cambrioleur avait lui aussi un histoire farfelue à raconter.`}</p>
    <p>{`Le cambrioleur s’était une fois fait agressé dans la rue. Un comble, n’est-ce pas ? Il se baladait innocemment quand un homme l’entraîna dans une ruelle. Menaçant et armé d’un pistolet, l’agresseur lui réclama son argent et ses objets de valeur. Le cambrioleur - qui n’était pas vraiment un cambrioleur au final - était sur le point de s’exécuter quand l’agresseur fut soudainement mis au sol par un miraculeux inconnu armé d’un taser. Plaquant l’embout du taser sur les côtes de l’agresseur, il ne lui avait laissé aucune chance et celui-ci gisait inconscient au sol. Notre cambrioleur n’eut même pas le temps de souffler ni de le remercier que son sauveur lui donna le taser, un sac contenant un manuel de physique ainsi que plusieurs objets dont un jeu de clés et une adresse. Il dit au cambrioleur agressé de se rendre à l’adresse indiquée, utiliser la clé pour entrer et ordonner à l’homme qui y habite de remettre le lot à un certain Franck Cornec. Ebahi, le cambrioleur lui demanda premièrement comment il avait su qu’il se trouverait dans une ruelle aussi sombre et étroite. Le sauveur avait lui aussi une histoire assez dingue à relater.`}</p>
    <p>{`Le sauveur se trouvait tranquillement seul à la terrasse d’un café en train de siroter son expresso au cours de sa pause déjeuner quand un inconnu vint s’assoir à sa table. Cet inconnu lui confia un sac contenant divers objets - dont le fameux manuel de physique - ainsi qu’un taser. Il lui donna l’adresse d’une ruelle où un individu allait se faire agresser et lui dit d’aller y utiliser son taser pour le sauver. Ensuite il devrait lui passer certaines instructions. Abasourdi, le saveur demanda comment il savait tout cela et pourquoi exécuter toutes ces instructions dans cet ordre précis. L’homme lui dit qu’il s’appelait Franck Cornec et qu’il venait du futur.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      